import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './information.css';

function Information() {
    return (
        <div className="d-flex justify-content-end align-items-center flex-wrap me-md-3">
            <h1 className="fs-6 text-sm mb-0 me-1 mt-1 information desktop-text" style={{ color: 'black' }}>
                Centro Kaili / contacto.centrokaili@gmail.com / Av. Apoquindo 6275 of. 132, Las Condes, Santiago
            </h1>
            <h1 className="text-sm mb-0 me-3 mt-1 mobile-text" style={{ color: 'black' }}>
                Av. Apoquindo 6275 of. 132, Las Condes, Santiago
            </h1>
            <a href="https://www.instagram.com/centro.kaili/" target="_blank" rel="noopener noreferrer" className="ms-3">
                <FontAwesomeIcon icon={faInstagram} style={{ color: '#E1306C' }} size='2x' />
            </a>
            <a href="https://api.whatsapp.com/message/WQHB6Z6KSCPOJ1?autoload=1&app_absent=0" target="_blank" rel="noopener noreferrer" className="ms-3">
                <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#25D366' }} size='2x' />
            </a>
        </div>
    )
}

export default Information;